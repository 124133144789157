import { graphql } from 'gatsby'

import { CheckoutLoadingPage } from '../../../../components/buy/checkout/CheckoutLoadingPage'
import { withDispensaryPageAdapter } from '../../../../hoc/withDispensaryPageAdapter'
import CheckoutRoutesPage from '../../../checkout/[...]'

// NOTE: keep this query synchronized with the one in ../../../checkout/[...].tsx
export const query = graphql`
  query DispensaryCheckoutPage {
    ...sanityCheckoutMarketingContent
  }
`

export default withDispensaryPageAdapter(CheckoutRoutesPage, CheckoutLoadingPage)
